import * as React from 'react';
import styled, { css } from 'styled-components';

import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts/InvestigationDrawer';
import { useGetInvestigationDetails } from 'security-app/hooks/useInvestigationsAPI';
import { Icon, Spinner } from 'components/common';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';

import Details from './Details';
import Evidence from './Evidence';
import Notes from './Notes';

const Backdrop = styled.div<{ $collapsed?: boolean }>(({ $collapsed }) => css`
  position: fixed;
  display: ${$collapsed ? 'none' : 'flex'};
  flex-direction: row;
  justify-content: flex-end;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 1050;
`);

const drawerWidth = 500;
const Drawer = styled.div<{ $collapsed?: boolean }>(({ $collapsed, theme }) => css`
  position: fixed;
  top: 50px;
  right: ${$collapsed ? -drawerWidth : '0'}px;
  bottom: 0;
  width: ${drawerWidth}px;
  font-size: .85rem;

  display: flex;
  flex-direction: column;

  background-color: ${theme.colors.global.background};
  border-left: 1px solid ${theme.colors.input.border};

  transition: all 0.3s ease-in-out;

  z-index: 1051;
`);

const DrawerContainer = styled.div`
  padding: 1rem;
  overflow-y: auto;
`;

const controlsWidth = 24;
const Controls = styled.div<{ $collapsed: boolean }>(({ $collapsed, theme }) => css`
  position: absolute;
  top: 7px;
  left: -${controlsWidth}px;
  width: ${controlsWidth}px;
  padding: 8px 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  border: 1px solid ${theme.colors.input.border};
  background-color: ${theme.colors.global.background};
  border-right: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  & > svg {
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }

  & > .collaps-trigger {
    transform: rotate(${$collapsed ? '180' : '0'}deg);
  }

  & > .close-trigger {
    color: ${theme.colors.brand.primary};
  }
`);

const SectionDivider = styled.hr(({ theme }) => css`
  border-color: ${theme.colors.input.border};
  margin: 16px 0;
`);

function InvestigationDrawer() {
  const {
    collapsed,
    setCollapsed,
    setShowDrawer,
    selectedInvestigationId,
    setSelectedInvestigationId,
  } = useInvestigationDrawer();
  const sendTelemetry = useSendTelemetry();
  const { loadingInvestigationDetails, currentInvestigation } = useGetInvestigationDetails(selectedInvestigationId);

  React.useEffect(() => setShowDrawer(!!selectedInvestigationId), [setShowDrawer, selectedInvestigationId]);

  const onClose = () => {
    sendTelemetry('click', {
      app_pathname: 'security',
      app_section: 'investigation',
      app_action_value: 'drawer-close',
    });

    setShowDrawer(false);
    setCollapsed(false);
    localStorage.removeItem('currentInvestigationId');
    setSelectedInvestigationId(null);
  };

  return (
    <>
      <Backdrop $collapsed={collapsed} onClick={() => setCollapsed(true)} />
      <Drawer $collapsed={collapsed}>
        <Controls $collapsed={collapsed}>
          <Icon className="close-trigger"
                name="times"
                title="close drawer"
                onClick={onClose} />
          <Icon className="collaps-trigger"
                name="chevron-right"
                title={`${collapsed ? 'Expand' : 'Collapse'} details`}
                onClick={() => setCollapsed(!collapsed)} />
        </Controls>
        {loadingInvestigationDetails || !currentInvestigation
          ? <Spinner text="Loading investigation details..." />
          : (
            <DrawerContainer style={{ overflowY: 'auto' }}>
              <h3>Investigation Details</h3>
              <SectionDivider />
              <Details investigation={currentInvestigation} />
              <SectionDivider />
              <Evidence investigation={currentInvestigation} />
              <SectionDivider />
              <Notes investigation={currentInvestigation} />
            </DrawerContainer>
          )}
      </Drawer>
    </>
  );
}

export default InvestigationDrawer;
