import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { BootstrapModalWrapper, Button, Modal } from 'components/bootstrap';

import InputProfileForm from './InputProfileForm';

import { InputProfilesActions } from '../stores/InputProfilesStore';
import type { InputProfile } from '../Types';

type Props = {
  inputProfile: InputProfile,
  buttonBsStyle: string,
  buttonBsSize?: string,
  buttonText?: string,
  onSave: (inputProfile: InputProfile) => void,
};

const EditInputProfileForm = ({ inputProfile, buttonBsStyle, buttonBsSize, buttonText, onSave }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const _openModal = () => setShowModal(true);
  const _closeModal = () => setShowModal(false);

  const handleSubmit = (nextInputProfile: InputProfile) => {
    InputProfilesActions.update(nextInputProfile, inputProfile.id)
      .then((result) => {
        onSave(result);

        return result;
      })
      .finally(_closeModal);
  };

  const modalTitle = inputProfile.id ? 'Update input profile' : 'Create input profile';

  return (
    <>
      <Button bsStyle={buttonBsStyle} bsSize={buttonBsSize} onClick={_openModal}>{buttonText}</Button>
      <BootstrapModalWrapper showModal={showModal}
                             onHide={_closeModal}
                             bsSize="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <InputProfileForm inputProfile={inputProfile}
                          onSubmit={handleSubmit}
                          onCancel={_closeModal}
                          buttonToolbarClassName="pull-right"
                          buttonText="Update"
                          InputsWrapper={Modal.Body}
                          ActionsWrapper={Modal.Footer} />
      </BootstrapModalWrapper>
    </>
  );
};

EditInputProfileForm.propTypes = {
  inputProfile: PropTypes.object,
  buttonBsStyle: PropTypes.string,
  buttonBsSize: PropTypes.string,
  buttonText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

EditInputProfileForm.defaultProps = {
  inputProfile: {},
  buttonBsStyle: 'info',
  buttonBsSize: null,
  buttonText: 'Edit',
};

export default EditInputProfileForm;
