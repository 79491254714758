import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { defaultCompare as naturalSort } from 'logic/DefaultCompare';
import connect from 'stores/connect';
import Routes from 'routing/Routes';
import { Col, Row, Input } from 'components/bootstrap';
import { Select, Spinner, ReadOnlyFormGroup, ConfirmDialog, FormSubmit } from 'components/common';
import NumberUtils from 'util/NumberUtils';
import DocumentationLink from 'components/support/DocumentationLink';
import AppConfig from 'util/AppConfig';
import type { Store } from 'stores/StoreTypes';
import ChecksumTypes from 'archive/logic/ChecksumTypes';
import CompressionTypes from 'archive/logic/CompressionTypes';
import BackendTypes from 'archive/logic/BackendTypes';
import ArchiveConfigStreamSelectionForm from 'archive/components/ArchiveConfigStreamSelectionForm';
import { ArchiveConfigurationActions } from 'archive/ArchiveConfigurationStore';
import type { ArchiveBackendsStoreState } from 'archive/ArchiveBackendsStore';
import ArchiveBackendsStore, { ArchiveBackendsActions } from 'archive/ArchiveBackendsStore';
import type { Backend, Pagination, ArchiveConfiguration } from 'archive/types';
import { BackendsPropType } from 'archive/propTypes';
import useHistory from 'routing/useHistory';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';

const isCloud = AppConfig.isCloud();

const StyledForm = styled.form`
  margin-top: 10px
`;

type Props = {
  backends: {
    backends: Array<Backend>,
    pagination: Pagination,
  },
};

const ArchiveConfigForm = ({ backends }: Props) => {
  const [configuration, setConfiguration] = useState<ArchiveConfiguration | undefined>();
  const [savedConfiguration, setSavedConfiguration] = useState<ArchiveConfiguration | undefined>();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [retentionTimeChanged, setRetentionTimeChanged] = useState<boolean>(false);
  const history = useHistory();
  const sendTelemetry = useSendTelemetry();

  useEffect(() => {
    ArchiveConfigurationActions.getConfig().then((config) => {
      setConfiguration(config);
      setSavedConfiguration(config);
    });

    // TODO: Magic pagination number - backend selection should probably be a search form instead of a drop-down
    ArchiveBackendsActions.listBackends(1, 1000);
  }, []);

  if (!configuration || !backends) {
    return <Spinner />;
  }

  const _updateConfig = (fieldName: string, value: any) => {
    const updatedConfig = { ...configuration, [fieldName]: value };

    if (fieldName === 'retention_time') {
      setRetentionTimeChanged(savedConfiguration.retention_time !== Number(value));
    }

    setConfiguration(updatedConfig);
  };

  const _onInputUpdate = (fieldName) => (e: React.ChangeEvent<HTMLInputElement>) => {
    _updateConfig(fieldName, e.target.value);
  };

  const _onChecksumTypeSelect = (selection: string) => {
    if (selection === '') {
      return;
    }

    _updateConfig('segment_checksum_type', selection);
  };

  const _onCompressionTypeSelect = (selection: string) => {
    if (selection === '') {
      return;
    }

    _updateConfig('segment_compression_type', selection);
  };

  const _onBackendTypeSelect = (selection: string) => {
    if (selection === '') {
      return;
    }

    _updateConfig('backend_id', selection);
  };

  const _onRestrictToLeaderChange = () => {
    _updateConfig('restrict_to_leader', !configuration.restrict_to_leader);
  };

  const _onExcludedStreamsChange = (excludedStreams: Array<string>) => {
    _updateConfig('excluded_streams', excludedStreams);
  };

  const _updateConfiguration = (): void => {
    ArchiveConfigurationActions.saveConfig(configuration).then(() => {
      history.replace(Routes.pluginRoute('SYSTEM_ARCHIVES'));
    });
  };

  const _saveConfiguration = (event) => {
    sendTelemetry('form_submit', {
      app_pathname: 'archive',
      app_section: 'archive-configuration',
      app_action_value: 'archive-configuration-update',
    });

    if (retentionTimeChanged) {
      setShowConfirmDialog(true);
    } else {
      _updateConfiguration();
    }

    event.preventDefault();
  };

  const _abortSaving = (): void => {
    setShowConfirmDialog(false);
  };

  const sortedBackends = backends.backends.map((backend) => ({ value: backend.id, label: `${backend.title} (${BackendTypes.getBackendType(backend.settings.type).label})` })).sort((a, b) => naturalSort(a.label.toLowerCase(), b.label.toLowerCase()));

  const retentionTimeString = ((retentionTime: undefined | number): string => {
    if (retentionTime === undefined || Number(retentionTime) === 0) {
      return 'unlimited';
    }

    return NumberUtils.formatNumber(retentionTime);
  });

  const currentBackend = backends.backends.find((backend) => backend.id === configuration.backend_id);
  const compressionTypesHelpText = (
    <span>
      Compression method to apply on archive files. Read the{' '}
      <DocumentationLink page="setup-archiving#compression-type" text="documentation" />{' '}
      for more information on the different options.
    </span>
  );
  const checksumTypesHelpText = (
    <span>
      Checksum method to apply on archive files. Read the{' '}
      <DocumentationLink page="setup-archiving#checksum-type" text="documentation" />{' '}
      for more information on the different options.
    </span>
  );

  const sortedCompressionTypes = CompressionTypes.compressionTypes.sort((t1, t2) => naturalSort(t1.label.toLowerCase(), t2.label.toLowerCase()));
  const currentRetentionTimeString = retentionTimeString(savedConfiguration?.retention_time);
  const newRetentionTimeString = retentionTimeString(configuration?.retention_time);
  const onCancel = () => history.push(Routes.pluginRoute('SYSTEM_ARCHIVES'));

  return (
    <div>
      <ConfirmDialog show={showConfirmDialog}
                     onConfirm={_updateConfiguration}
                     onCancel={_abortSaving}
                     title="Are you sure?">
        <>
          <p>
            Archive retention time will be configured to {newRetentionTimeString} days. Current configuration
            is {currentRetentionTimeString} days.
          </p>
          <p>
            Deleted archives can&apos;t be restored.
          </p>
          <p>Do you want to continue?</p>
        </>
      </ConfirmDialog>
      <h2>Archives configuration</h2>
      <StyledForm className="form form-horizontal" onSubmit={_saveConfiguration}>
        <fieldset>
          {isCloud && (
            <>
              <ReadOnlyFormGroup label="Backend" value={currentBackend?.title} />
              <ReadOnlyFormGroup label="Retention Time (days)" value={configuration.retention_time} />
            </>
          )}
          {!isCloud && (
            <>
              <Input id="backend-select"
                     label="Backend"
                     required
                     autoFocus
                     help="In which backend should the archives be stored?"
                     labelClassName="col-sm-3"
                     wrapperClassName="col-sm-9">
                <Select placeholder="Select Backend"
                        clearable={false}
                        options={sortedBackends}
                        matchProp="label"
                        onChange={_onBackendTypeSelect}
                        value={configuration.backend_id} />
              </Input>
              <Input id="timeout-controls"
                     labelClassName="col-sm-3"
                     wrapperClassName="col-sm-9"
                     label="Restrict to leader node">
                <Input type="checkbox"
                       id="config-restrict-to-leader"
                       label="Restrict Archive restore jobs to run only on the leader node"
                       checked={configuration.restrict_to_leader}
                       onChange={_onRestrictToLeaderChange}
                       help="Only turn this flag off if all nodes operate on the same shared filesystem for the Archiving backend.
                    Or if a S3 backend is used."
                       labelClassName="col-sm-3"
                       wrapperClassName="col-sm-9" />
              </Input>
              <Input type="text"
                     id="config-max-segment-size"
                     label="Max Segment Size"
                     onChange={_onInputUpdate('max_segment_size')}
                     value={configuration.max_segment_size}
                     help={<span>Maximum size for each message segment in <strong>bytes</strong>.</span>}
                     labelClassName="col-sm-3"
                     wrapperClassName="col-sm-9"
                     required />
              <Input id="compresion-type-select"
                     label="Compression Type"
                     help={compressionTypesHelpText}
                     labelClassName="col-sm-3"
                     wrapperClassName="col-sm-9">
                <Select placeholder="Select Compression Type"
                        options={sortedCompressionTypes}
                        matchProp="label"
                        onChange={_onCompressionTypeSelect}
                        value={CompressionTypes.getCompressionType(configuration.segment_compression_type)?.value} />
              </Input>
              <Input id="checksum-type-select"
                     label="Checksum Type"
                     help={checksumTypesHelpText}
                     labelClassName="col-sm-3"
                     wrapperClassName="col-sm-9">
                <Select placeholder="Select Checksum Type"
                        options={ChecksumTypes.checksumTypes}
                        matchProp="label"
                        onChange={_onChecksumTypeSelect}
                        value={ChecksumTypes.getChecksumType(configuration.segment_checksum_type)?.value} />
              </Input>
              <Input type="text"
                     id="config-restore-index-batch-size"
                     label="Restore index batch size"
                     onChange={_onInputUpdate('restore_index_batch_size')}
                     value={configuration.restore_index_batch_size}
                     help="When restoring an archive, what batch size should be used to re-import the data?"
                     labelClassName="col-sm-3"
                     wrapperClassName="col-sm-9" />
              <Input type="text"
                     id="config-failure-threshold"
                     label="Failure notification threshold"
                     onChange={_onInputUpdate('archive_failure_threshold')}
                     value={configuration.archive_failure_threshold}
                     help="Number of consecutive failed attempts before showing an error notification"
                     labelClassName="col-sm-3"
                     wrapperClassName="col-sm-9" />
              <Input type="number"
                     min="0"
                     id="config-retention-time"
                     label="Retention Time (days)"
                     onChange={_onInputUpdate('retention_time')}
                     value={configuration.retention_time}
                     help="Define how many days to keep archives before deleting them. When 0 is set, automatic deletion is disabled."
                     labelClassName="col-sm-3"
                     wrapperClassName="col-sm-9" />
            </>
          )}
          <Input id="config-excluded-streams"
                 label="Streams to archive"
                 help="Select streams that should be included in the archive. New streams will be archived by default."
                 labelClassName="col-sm-3"
                 wrapperClassName="col-sm-9">
            <ArchiveConfigStreamSelectionForm excludedStreams={configuration.excluded_streams}
                                              updateExcludedStreams={_onExcludedStreamsChange} />
          </Input>
          <Row>
            <Col smOffset={3} sm={9}>
              <FormSubmit submitButtonText="Update configuration" onCancel={onCancel} />
            </Col>
          </Row>
        </fieldset>
      </StyledForm>
    </div>
  );
};

ArchiveConfigForm.propTypes = {
  backends: BackendsPropType,
};

ArchiveConfigForm.defaultProps = {
  backends: undefined,
};

export default connect(
  ArchiveConfigForm,
  {
    archiveBackends: ArchiveBackendsStore as Store<ArchiveBackendsStoreState>,
  },
  ({ archiveBackends, ...otherProps }) => ({
    backends: archiveBackends.backends,
    ...otherProps,
  }),
);
