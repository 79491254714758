import React, { useCallback } from 'react';

import type { UserSearchFilter } from 'search-filter/types';
import { Col, Row } from 'components/bootstrap';
import UserSearchFilterForm from 'search-filter/components/filter-management-pages/UserSearchFilterForm';
import { Headline } from 'components/common/Section/SectionComponent';
import Routes from 'routing/Routes';
import useUserSearchFilterMutation from 'search-filter/hooks/useUserSearchFilterMutation';
import { CurrentUserStore } from 'stores/users/CurrentUserStore';
import useHistory from 'routing/useHistory';

const UserSearchFilterCreate = () => {
  const { post } = useUserSearchFilterMutation();
  const history = useHistory();

  const onSave = useCallback((searchFilter: UserSearchFilter) => post({ data: searchFilter }).then(() => CurrentUserStore.reload().then(() => {
    history.push(Routes.pluginRoute('MY-FILTERS'));
  })), [history, post]);

  return (
    <Row className="content">
      <Col xs={12}>
        <Headline>Create Filter</Headline>
      </Col>
      <Col xs={12}>
        <UserSearchFilterForm onSubmit={onSave} submitButtonText="Create filter" submitLoadingText="Creating filter..." />
      </Col>
    </Row>
  );
};

export default UserSearchFilterCreate;
