import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { defaultCompare } from 'logic/DefaultCompare';
import { SelectableList, Spinner } from 'components/common';
import { HelpBlock, MenuItem, Button, BootstrapModalForm, Input, BootstrapModalConfirm } from 'components/bootstrap';

import type { Forwarder } from '../Types';
import { ForwarderPropType } from '../Types';

type Props = {
  inputProfileId: string,
  isButton: boolean,
  forwarders: Array<Forwarder>,
  assignedForwarders?: Array<Forwarder>,
  onForwardersAssign: (updatedForwarders: Array<{forwarderId: string, inputProfileId: string | null}>) => void,
};

const InputProfileAssignForm = ({ inputProfileId, isButton, forwarders, assignedForwarders = [], onForwardersAssign }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [selectedForwarders, setSelectedForwarders] = useState(null);

  const _getFormattedForwarders = (forwardersList: Array<Forwarder>) => forwardersList.map((i) => ({ value: i.id, label: i.title }))
    .sort((s1, s2) => defaultCompare(s1.label, s2.label));

  const currentAssignedForwarders = useMemo(() => _getFormattedForwarders(assignedForwarders), [assignedForwarders]);
  const effectiveAssignedForwarders = selectedForwarders || currentAssignedForwarders;

  const _openModal = () => setShowModal(true);

  const _closeModal = () => setShowModal(false);

  const _openConfirmation = () => setShowConfirmModal(true);

  const _closeConfirmation = () => setShowConfirmModal(false);

  const _closeModalsAndResetState = () => {
    _closeModal();
    _closeConfirmation();

    if (selectedForwarders) {
      setSelectedForwarders(null);
    }
  };

  const _onSelectedForwardersChange = (selected) => {
    setSelectedForwarders(selected);
  };

  const _getFilteredForwarders = () => forwarders.filter((f) => !effectiveAssignedForwarders.some((sf) => sf.value === f.id));

  const getUnassignedForwarders = (ids: Array<string>) => assignedForwarders.filter((forwarder) => !ids.includes(forwarder.id))
    .map((forwarder) => ({ forwarderId: forwarder.id, inputProfileId: null }));

  const _saveUpdatedForwarders = () => {
    const forwardersIds = effectiveAssignedForwarders.map((sf) => sf.value);
    const assignedForwardersList = forwardersIds.map((id) => ({ forwarderId: id, inputProfileId }));
    const unassignedForwarders = getUnassignedForwarders(forwardersIds);

    onForwardersAssign([...assignedForwardersList, ...unassignedForwarders]);

    _closeModalsAndResetState();
  };

  const _updateForwarder = () => {
    const forwardersIds = effectiveAssignedForwarders.map((sf) => sf.value);
    const unassignedForwarders = getUnassignedForwarders(forwardersIds);

    if (unassignedForwarders.length > 0) {
      _openConfirmation();

      return;
    }

    _saveUpdatedForwarders();
  };

  if (!forwarders) {
    return (<Spinner />);
  }

  return (
    <>
      {isButton ? <Button bsStyle="success" onClick={_openModal}>Assign to Forwarders</Button>
        : <MenuItem eventKey="3" onClick={_openModal}>Assign to Forwarders</MenuItem>}
      <BootstrapModalForm show={showModal}
                          title="Assign Input Profile to Forwarders"
                          onSubmitForm={_updateForwarder}
                          onCancel={_closeModalsAndResetState}
                          submitButtonText="Save">
        <fieldset>
          <Input id="input-profile-forwarder-select"
                 label="Forwarders"
                 help={<HelpBlock>Select the new Input Profile you want to use on this Forwarder.</HelpBlock>}>
            <SelectableList options={_getFormattedForwarders(_getFilteredForwarders())}
                            isLoading={false}
                            onChange={_onSelectedForwardersChange}
                            selectedOptionsType="object"
                            selectedOptions={effectiveAssignedForwarders} />
          </Input>
        </fieldset>
      </BootstrapModalForm>
      <BootstrapModalConfirm showModal={showConfirmModal}
                             title="Assign Input Profile"
                             onConfirm={_saveUpdatedForwarders}
                             onCancel={_closeModalsAndResetState}>
        {`Are you sure you want to remove this input profile for some forwarders, 
          this will remove all inputs running on those forwarders? This action cannot be undone.`}
      </BootstrapModalConfirm>
    </>
  );
};

InputProfileAssignForm.propTypes = {
  inputProfileId: PropTypes.string.isRequired,
  isButton: PropTypes.bool.isRequired,
  forwarders: PropTypes.arrayOf(ForwarderPropType),
  assignedForwarders: PropTypes.arrayOf(ForwarderPropType),
  onForwardersAssign: PropTypes.func.isRequired,
};

InputProfileAssignForm.defaultProps = {
  forwarders: [],
  assignedForwarders: [],
};

export default InputProfileAssignForm;
